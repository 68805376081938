import { SVGAttributes } from 'react'

export default function Family(attributes: SVGAttributes<SVGSVGElement>): JSX.Element {
	return (
		<svg {...attributes} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97 96">
			<path className="text-gray-800" d="M48.5 96c26.51 0 48-21.49 48-48S75.01 0 48.5 0 .5 21.49.5 48s21.49 48 48 48z" fill="currentColor"/>
			<path className="text-gray-300" d="M50.4 69.4c1.2 0 2.6-.8 3.1-1.9v18.4c2.8-.4 5.5-1 8.2-2V69.6h1.4v13.7c2.9-1.2 5.6-2.8 8.2-4.7V67.5c.5 1.1 1.9 1.9 3.1 1.9 1.6 0 3-1.4 3-3v-18c0-7.8-5.4-12.5-14.5-12.7h-1.1c-9 .2-14.3 4.9-14.3 12.6v18c0 1.7 1.3 3.1 2.9 3.1z" fill="currentColor"/>
			<path className="text-gray-300" d="M22.3 69.4c1.2 0 2.6-.8 3.1-1.9v11c2.6 2 5.3 3.5 8.2 4.7V69.6H35v14.2c2.7 1 5.4 1.7 8.2 2.1V67.5c.5 1.1 1.9 1.9 3.1 1.9 1.6 0 3-1.4 3-3v-18c0-7.8-5.4-12.5-14.5-12.7h-1.1c-9.1.2-14.4 4.9-14.4 12.7v18c.1 1.6 1.4 3 3 3z" fill="currentColor"/>
			<path className="text-white" d="M62.4 69.1h-1.2V84c.3-.1.7-.3 1-.4V70.1h.5v13.4c.3-.1.7-.3 1-.4v-14h-1.3z" fill="currentColor"/>
			<path className="text-white" d="M50.4 69.9c.9 0 1.9-.4 2.6-1v17c.3 0 .7-.1 1-.1V58.7 46.8c0-.1 0-.3-.4-.3-.3 0-.4.2-.4.3v19.5c0 1.4-1.5 2.5-2.8 2.5-1.4 0-2.5-1.1-2.5-2.5v-18c0-7.5 5.2-12.1 14.4-12.1s14.4 4.7 14.4 12.1v18c0 1.4-1.1 2.5-2.5 2.5s-2.8-1.1-2.8-2.5V46.8c0-.1 0-.3-.4-.3s-.4.2-.4.3V79c.3-.3.7-.5 1-.8v-9.3c.7.6 1.7 1 2.6 1 1.9 0 3.5-1.6 3.5-3.5v-18c0-4-1.4-7.3-4.1-9.7-2.5-2.1-6-3.3-10.3-3.5h-2c-4.3.2-7.8 1.4-10.3 3.5-2.7 2.3-4.1 5.6-4.1 9.7v18c0 1.9 1.6 3.5 3.5 3.5zM34.3 69.1h-1.2V83c.3.2.7.3 1 .4V70.1h.4v13.5c.3.1.7.3 1 .4V69.1h-1.2z" fill="currentColor"/>
			<path className="text-white" d="M22.3 69.9c.9 0 1.9-.4 2.6-1v9.2c.3.3.7.5 1 .8V58.7 46.8c0-.1 0-.3-.4-.3-.3 0-.4.2-.4.3v19.5c0 1.4-1.5 2.5-2.8 2.5-1.4 0-2.5-1.1-2.5-2.5v-18c0-7.5 5.2-12.1 14.4-12.1s14.4 4.7 14.4 12.1v18c0 1.4-1.1 2.5-2.5 2.5s-2.8-1.1-2.8-2.5V46.8c0-.1 0-.3-.4-.3s-.4.2-.4.3v39c.3.1.7.1 1 .1v-17c.7.6 1.7 1 2.6 1 1.9 0 3.5-1.6 3.5-3.5v-18c0-4-1.4-7.3-4.1-9.7-2.5-2.1-6-3.3-10.3-3.5h-2c-4.3.2-7.8 1.4-10.3 3.5-2.7 2.3-4.1 5.6-4.1 9.7v18c0 1.9 1.6 3.5 3.5 3.5z" fill="currentColor"/>
			<path className="text-gray-300" d="M63.3 83.2V66.3c0-7.8-5.4-12.5-14.5-12.7h-1.1c-9.1.2-14.4 4.9-14.4 12.7v16.8c9.6 4.1 20.5 4.1 30 .1z" fill="currentColor"/>
			<path className="text-white" d="M33.9 83text-white.3v-17c0-7.5 5.2-12.1 14.4-12.1s14.4 4.7 14.4 12.1v17.2c.3-.1.7-.3 1-.4V66.3c0-4-1.4-7.3-4.1-9.7-2.5-2.1-6-3.3-10.3-3.5h-2c-4.3.2-7.8 1.4-10.3 3.5-2.7 2.3-4.1 5.6-4.1 9.7v16.6c.3.1.6.3 1 .4z" fill="currentColor"/>
			<path className="text-white" d="M39.6 64.4c-.3 0-.4.2-.4.3v19.5c0 .3-.1.5-.2.7.3.1.6.2.9.2v-8.6-11.9c.1.1.1-.2-.3-.2zM57.5 84.3V64.8c0-.1 0-.3-.4-.3s-.4.2-.4.3v20.6c.3-.1.6-.1 1-.2-.1-.4-.2-.7-.2-.9z" fill="currentColor"/>
			<path className="text-green-400" d="M48.4 55.1c-4 0-7.2-3.2-7.2-7.2s3.2-7.2 7.2-7.2 7.2 3.2 7.2 7.2-3.2 7.2-7.2 7.2z" fill="currentColor"/>
			<path className="text-green-400" d="M48.4 55.1c-4 0-7.2-3.2-7.2-7.2s3.2-7.2 7.2-7.2 7.2 3.2 7.2 7.2-3.2 7.2-7.2 7.2z" fill="currentColor"/>
			<path className="text-green-400" d="M48.4 40.7c4.2 0 7.5 3.4 7.5 7.5s-3.4 7.5-7.5 7.5c-4.2 0-7.5-3.4-7.5-7.5s3.3-7.5 7.5-7.5z" fill="currentColor"/>
			<path className="text-gray-800" d="M48.4 56.3c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zm0-15.1c-3.9 0-7 3.2-7 7 0 3.9 3.2 7 7 7s7-3.2 7-7-3.1-7-7-7z" fill="currentColor"/>
			<path className="text-white" d="M62.5 37.2c-4 0-7.2-3.2-7.2-7.2s3.2-7.2 7.2-7.2 7.2 3.2 7.2 7.2-3.2 7.2-7.2 7.2z" fill="currentColor"/>
			<path className="text-white" d="M62.5 37.2c-4 0-7.2-3.2-7.2-7.2s3.2-7.2 7.2-7.2 7.2 3.2 7.2 7.2-3.2 7.2-7.2 7.2z" fill="currentColor"/>
			<path className="text-white" d="M62.5 22.8c4.2 0 7.5 3.4 7.5 7.5s-3.4 7.5-7.5 7.5c-4.2 0-7.5-3.4-7.5-7.5s3.3-7.5 7.5-7.5z" fill="currentColor"/>
			<path className="text-gray-800" d="M62.5 38.4c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zm0-15.1c-3.9 0-7 3.2-7 7s3.2 7 7 7 7-3.2 7-7-3.1-7-7-7z" fill="currentColor"/>
			<path className="text-white" d="M34.4 37.2c-4 0-7.2-3.2-7.2-7.2s3.2-7.2 7.2-7.2 7.2 3.2 7.2 7.2-3.2 7.2-7.2 7.2z" fill="currentColor"/>
			<path className="text-white" d="M34.4 37.2c-4 0-7.2-3.2-7.2-7.2s3.2-7.2 7.2-7.2 7.2 3.2 7.2 7.2-3.2 7.2-7.2 7.2z" fill="currentColor"/>
			<path className="text-white" d="M34.4 22.8c4.2 0 7.5 3.4 7.5 7.5s-3.4 7.5-7.5 7.5c-4.2 0-7.5-3.4-7.5-7.5s3.3-7.5 7.5-7.5z" fill="currentColor"/>
			<path className="text-gray-800" d="M34.4 38.4c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zm0-15.1c-3.9 0-7 3.2-7 7s3.2 7 7 7 7-3.2 7-7-3.1-7-7-7z" fill="currentColor"/>
		</svg>
	)
}

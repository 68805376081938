import { SVGAttributes } from 'react'

export default function Food(attributes: SVGAttributes<SVGSVGElement>): JSX.Element {
	return (
		<svg {...attributes} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97 96">
			<path className="text-gray-300" d="M48.5 77.184c16.118 0 29.184-13.066 29.184-29.184 0-16.118-13.066-29.184-29.184-29.184-16.118 0-29.184 13.066-29.184 29.184 0 16.118 13.066 29.184 29.184 29.184z" fill="currentColor"/>
			<path className="text-gray-800" d="M48.5 96c26.51 0 48-21.49 48-48S75.01 0 48.5 0 .5 21.49.5 48s21.49 48 48 48z" fill="currentColor"/>
			<path className="text-gray-300" d="M48.5 77.184c16.118 0 29.184-13.066 29.184-29.184 0-16.118-13.066-29.184-29.184-29.184-16.118 0-29.184 13.066-29.184 29.184 0 16.118 13.066 29.184 29.184 29.184z" fill="currentColor"/>
			<path className="text-white" d="M48.5 67.584c10.816 0 19.584-8.768 19.584-19.584 0-10.816-8.768-19.584-19.584-19.584-10.816 0-19.584 8.768-19.584 19.584 0 10.816 8.768 19.584 19.584 19.584z" stroke="currentColor" strokeWidth="3.811" strokeMiterlimit="10"/>
			<path className="text-white" d="M48.5 67.584c10.816 0 19.584-8.768 19.584-19.584 0-10.816-8.768-19.584-19.584-19.584-10.816 0-19.584 8.768-19.584 19.584 0 10.816 8.768 19.584 19.584 19.584z" stroke="currentColor" strokeWidth="3.811" strokeMiterlimit="10"/>
			<path className="text-green-400" d="M22.644 74.944c-1.792 0-4.48-1.728-4.48-5.44 0-1.408.448-4.224.896-7.488.512-3.456 1.088-7.36 1.088-9.28v-1.28c0-1.6.064-6.528-.256-7.36-.064-.064-.384-.32-.576-.512-1.472-1.152-4.096-3.328-4.096-9.216 0-4.992 3.52-13.312 7.488-13.312 3.968 0 7.488 8.32 7.488 13.312 0 5.952-2.688 8.064-4.096 9.216-.256.192-.576.448-.64.576-.32.832-.256 5.696-.256 7.296v1.28c0 1.856.576 5.824 1.088 9.28.512 3.264.896 6.144.896 7.488 0 3.712-2.688 5.44-4.544 5.44z" fill="currentColor"/>
			<path className="text-gray-800" d="M22.644 22.272c2.752 0 6.208 7.104 6.208 12.096 0 7.104-4.032 8-4.608 9.216-.576 1.216-.32 7.36-.32 9.152 0 3.712 1.984 14.016 1.984 16.768 0 3.008-2.112 4.224-3.264 4.224-1.152 0-3.264-1.216-3.264-4.224 0-2.752 1.984-13.12 1.984-16.768 0-1.792.192-7.936-.32-9.152-.576-1.216-4.608-2.112-4.608-9.216 0-5.056 3.456-12.096 6.208-12.096zm0-2.496c-5.12 0-8.704 9.408-8.704 14.528 0 6.528 3.072 9.024 4.608 10.176.064.064.192.128.256.192.192 1.344.128 5.312.128 6.656v1.28c0 1.792-.576 5.696-1.088 9.088-.512 3.328-.896 6.208-.896 7.68 0 4.096 3.008 6.656 5.76 6.656s5.76-2.56 5.76-6.656c0-1.472-.448-4.352-.896-7.68-.512-3.392-1.088-7.296-1.088-9.088v-1.28c0-1.344-.064-5.312.128-6.656.064-.064.192-.128.256-.192 1.472-1.216 4.608-3.712 4.608-10.176-.128-5.056-3.712-14.528-8.832-14.528z" fill="currentColor"/>
			<path className="text-green-400" d="M75.06 74.944c-1.792 0-4.48-1.728-4.48-5.44 0-1.408.448-4.224.896-7.488.512-3.456 1.088-7.36 1.088-9.28v-1.28c0-1.6.064-6.528-.256-7.424-.256-.512-.704-1.152-1.152-1.792-1.024-1.472-2.304-3.264-2.304-5.568s.192-5.888.512-9.024c.576-5.76 1.024-6.656 2.304-6.656.512 0 .896.192 1.152.64.256-.384.64-.512 1.152-.512.512 0 .832.128 1.088.512.256-.384.64-.512 1.088-.512.512 0 .896.192 1.152.512.256-.448.576-.64 1.152-.64 1.28 0 1.728.896 2.304 6.656.32 3.136.512 6.72.512 9.024 0 2.304-1.28 4.096-2.304 5.568-.448.64-.896 1.28-1.152 1.792-.32.832-.256 5.76-.256 7.424v1.28c0 1.856.576 5.824 1.088 9.28.512 3.264.896 6.144.896 7.488 0 3.712-2.688 5.44-4.48 5.44z" fill="currentColor"/>
			<path className="text-gray-800" d="M78.452 22.272c.768 0 1.6 9.984 1.6 14.464 0 2.88-2.496 4.928-3.328 6.848-.512 1.216-.32 7.36-.32 9.152 0 3.712 1.984 14.016 1.984 16.768 0 3.008-2.112 4.224-3.264 4.224-1.152 0-3.264-1.216-3.264-4.224 0-2.752 1.984-13.12 1.984-16.768 0-1.792.192-7.936-.32-9.152-.896-1.984-3.328-3.968-3.328-6.848 0-4.48.832-14.464 1.6-14.464.832 0 .128 12.864 1.024 12.864 1.024 0 .128-12.8 1.216-12.8 1.024 0 .256 12.736 1.088 12.736.896 0 .128-12.736 1.088-12.736 1.088 0 .256 12.8 1.216 12.8.832 0 .192-12.864 1.024-12.864zm0-2.496c-.512 0-.896.128-1.216.256a2.653 2.653 0 00-1.024-.192c-.448 0-.768.064-1.088.192a2.99 2.99 0 00-1.088-.192c-.384 0-.768.064-1.024.192-.32-.192-.704-.256-1.216-.256-2.304 0-2.752 2.432-3.008 3.648a39.261 39.261 0 00-.512 4.096c-.32 3.136-.512 6.848-.512 9.152 0 2.688 1.472 4.736 2.56 6.272.384.576.768 1.088.96 1.536.256 1.088.192 5.504.192 6.976v1.28c0 1.792-.576 5.696-1.088 9.088-.512 3.328-.896 6.208-.896 7.68 0 4.096 3.008 6.656 5.76 6.656s5.76-2.56 5.76-6.656c0-1.472-.448-4.352-.896-7.68-.512-3.392-1.088-7.296-1.088-9.088v-1.28c0-1.472-.064-5.888.192-6.976.192-.448.576-.96.96-1.536 1.088-1.472 2.56-3.584 2.56-6.272 0-2.304-.192-6.016-.512-9.152-.192-1.728-.32-3.072-.512-4.096-.512-1.152-.96-3.648-3.264-3.648z" fill="currentColor"/>
		</svg>
	)
}

import { FunctionComponent, HTMLAttributes, SVGAttributes, useState } from 'react'

import Bandage from '@/components/icons/careers/Bandage'
import Diversity from '@/components/icons/careers/Diversity'
import Family from '@/components/icons/careers/Family'
import Flexibility from '@/components/icons/careers/Flexibility'
import Food from '@/components/icons/careers/Food'
import Giving from '@/components/icons/careers/Giving'
import Heart from '@/components/icons/careers/Heart'
import Vacation from '@/components/icons/careers/Vacation'
import Layout from '@/components/Layout'
import Building from '@/public/img/careers/building.jpg'
import Top200WorkplacesImg from '@/public/img/careers/top-200-workplaces.png'

const Card: FunctionComponent<{
	title: string,
	Image: (attributes: SVGAttributes<SVGSVGElement>) => JSX.Element,
	className?: HTMLAttributes<HTMLDivElement>['className'],
}> = ({ Image, title, children, className }) => (
	<div className={`text-center bg-gray-100 shadow-md rounded-2xl flex flex-col grow max-w-xs px-4 pb-4 ${className ?? ''}`}>
		<Image className="w-24 h-24 mx-auto -mt-8" />
		<h3 className="font-extrabold uppercase text-gray-800 text-xl mt-6">{title}</h3>
		<p className="leading-5 mt-1">{children}</p>
	</div>
)

const Testimonials = () => {
	const [currentIndex, setCurrentIndex] = useState(0)
	const testimonies = [
		'The ownership, leadership, and managers truly care about every single employee. Pace is not a company that seeks profit above all else. They genuinely seem to prioritize and care for the health, safety, and development of its employees. It has been a fantastic breath of fresh air, so much so that I actually enjoy going to work each day. I also make more money here than I would at a comparable job elsewhere!',
		'I can honestly say I am excited to come to work each day. The people here are awesome and I feel valued as an employee. I feel like my work has a real impact on the performance of the company and my efforts are appreciated by my coworkers. It is a fun, collaborative environment and I hope to be here for a long time!',
		'I have worked at Pace for 2+ years it is a great place to work. All of the managers are a great asset to Pace and they give you good credit for your job that is done. It is a great place to work!',
	]

	return (
		<section className="relative w-full mt-9">
			<div className="mx-12 lg:mx-32 relative">
				<div className="italic text-lg md:text-2xl lg:text-3xl">
					<div className="absolute inset-0">
						<div className="relative">
							<p className="absolute font-extrabold text-green-400 -top-4 text-6xl -ml-8 lg:text-9xl lg:-left-20">&ldquo;</p>
							<p className="absolute font-extrabold text-green-400 bottom-0 right-0 text-6xl -mr-6 lg:text-9xl lg:-mr-16">&bdquo;</p>
							<p>{testimonies[currentIndex]}</p>
						</div>
					</div>
					<p aria-hidden="true" className="invisible">The ownership, leadership, and managers truly care about every single employee. Pace is not a company that seeks profit above all else. They genuinely seem to prioritize and care for the health, safety, and development of its employees. It has been a fantastic breath of fresh air, so much so that I actually enjoy going to work each day. I also make more money here than I would at a comparable job elsewhere!</p>
				</div>
			</div>
			<div className="mt-6 flex justify-center items-end space-x-9">
				{testimonies.map((testimony, index) => (
					<button className="rounded-full" key={testimony} onClick={() => setCurrentIndex(index)}>
						<div className="">
							<div className={`h-9 w-9 flex justify-center items-center text-white font-extrabold rounded-full focus:ring-offset-gray-100 ${currentIndex === index ? 'bg-gray-800' : 'bg-gradient-to-b from-gray-200 via-gray-500 to-gray-500'}`}>
								{index + 1}
							</div>
						</div>
					</button>
				))}
			</div>
		</section>
	)
}

export default function Careers(): JSX.Element {
	return (
		<>
			<section className="relative overflow-x-hidden pb-12 -mb-12">
				<div className="absolute top-0 left-0 right-0 md:hidden">
					<img className="w-full" src={Building} alt="Pace International Building" />
				</div>
				<div className="container mx-auto relative px-4 sm:px-12 sm:pt-16">
					<div className="hidden md:block">
						<div className="absolute top-0 right-0 h-24 mt-8 -mr-56 lg:-mr-40 2xl:-mr-28 ">
							<svg className="h-full" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 96">
								<path className="text-teal-400" stroke="currentColor" d="M271.405 80.405c-36.333-4.474-73.36-8.485-110.619-11.802" strokeWidth="12" strokeMiterlimit="10" strokeLinecap="round" />
								<path className="text-green-400" stroke="currentColor" d="M216.352 30.965C148.083 23.637 78.118 18.237 9 16" strokeWidth="16" strokeMiterlimit="10" strokeLinecap="round" />
								<path className="text-green-500" stroke="currentColor" d="M330.957 55.412C250.191 44.922 166.726 36.05 82.721 29.724" strokeWidth="16" strokeMiterlimit="10" strokeLinecap="round" style={{ mixBlendMode: 'multiply' }} />
							</svg>
						</div>
						<div className="absolute bottom-0 left-0 h-24 -ml-56 lg:-ml-40 2xl:-ml-28">
							<svg className="h-full" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 96">
								<path className="text-teal-400" stroke="currentColor" d="M68.552 16c36.333 4.474 73.36 8.485 110.618 11.802" strokeWidth="12" strokeMiterlimit="10" strokeLinecap="round" />
								<path className="text-green-400" stroke="currentColor" d="M123.605 65.44c68.269 7.328 138.235 12.728 207.352 14.965" strokeWidth="16" strokeMiterlimit="10" strokeLinecap="round" />
								<path className="text-green-500" stroke="currentColor" d="M9 40.993c80.766 10.491 164.231 19.362 248.236 25.688" strokeWidth="16" strokeMiterlimit="10" strokeLinecap="round" style={{ mixBlendMode: 'multiply' }} />
							</svg>
						</div>
					</div>
					<div className="relative pt-[calc(12rem-14%)] flex justify-center items-start text-center sm:pt-8 sm:text-left md:pt-0">
						<div className="max-w-sm bg-white shadow-2xl px-6 py-6 border border-gray-200 rounded-lg z-10 sm:mt-10 sm:max-w-xl sm:px-12 sm:py-6 sm:shrink-0 md:max-w-sm md:px-8 md:rounded-xl lg:rounded-2xl xl:px-12 xl:max-w-xl xl:rounded-3xl">
							<h1 className="font-extrabold text-3xl sm:text-5xl md:text-3xl xl:text-5xl">Find your next career at Pace International</h1>
							<p className="mt-4 text-lg sm:text-2xl md:text-base xl:text-2xl">Leading, simplifying, and supporting the integration of hardware and content solutions since 1972.</p>

							<div className="mt-8 flex justify-center md:justify-start">
								<a className="inline-block uppercase font-extrabold text-sm text-white bg-gradient-to-b from-green-400 via-green-500 to-green-500 py-2 px-4 rounded-full sm:py-3 sm:px-6 sm:text-lg md:text-sm xl:text-lg" target="_blank" href="https://www.indeed.com/jobs?q=Pace%20International&l=Rochester%2C%20MN&vjk=b0629b2060e2777c" rel="noreferrer noopener">View Open Positions</a>
							</div>
						</div>

						<div className="flex-1 -ml-20 shadow-xl border border-gray-200 rounded-lg overflow-hidden hidden md:rounded-xl md:block md:h-96 md:-ml-48 lg:rounded-2xl xl:h-auto xl:rounded-3xl 2xl:-ml-20">
							<img className="w-full h-full object-cover md:object-left lg:object-center" src={Building} alt="Pace International Building" />
						</div>
					</div>
				</div>
			</section>
			<section className="container mx-auto px-4 py-20 md:py-24">
				<p className="max-w-7xl text-center mx-auto sm:leading-7 text-base sm:text-lg xl:text-xl">As the saying goes “do what you love, and you’ll never work a day in your life.” – that’s how we operate. We’re passionate, creative, ambitious, and driven to support our partners and customers in the best way we know how. At Pace International everyone is invited to combine their talent and imagination to develop a fun and efficient workplace.</p>
				<div className="flex flex-wrap justify-center gap-x-12 gap-y-16 pt-20 md:pt-32 md:gap-y-24">
					<Card Image={Bandage} title="Healthcare" >
						Taking care of our team is about more than what goes on in the office. We’ve got your healthcare covered so you can work with peace of mind.
					</Card>
					<Card Image={Heart} title="Wellness">
						Healthy habits = healthy minds. At Pace, we value our employees’ physical and mental health, which is why we encourage the use of our in-office gym, game room and lounge.
					</Card>
					<Card Image={Food} title="Food">
						Enjoy occasional catered lunches, snacks, and strong caffeine in the office. We’re all about keeping our hardworking employees energized.
					</Card>
					<Card Image={Vacation} title="Vacation">
						We love our work but taking time off is necessary to maintaining a healthy balance. Our PTO plan gives you the relaxation time you need when you need it.
					</Card>
					<Card Image={Giving} title="Giving Back">
						We value the well-being and productivity of our communities and are proud to support causes like Adopt a Family and Toys for Tots.
					</Card>
					<Card Image={Family} title="Family">
						Let’s be real -- you work so you can lead the life you want. Enjoy flexible parental leave options.
					</Card>
					<Card Image={Flexibility} title="Flexibility">
						We get it, life happens. Pace is proud to offer flexible work options that fit your needs. (Not applicable to some positions.)
					</Card>
					<Card Image={Diversity} title="Diversity">
						You are welcome here. Pace is proud to offer a diverse workplace, as we believe that different perspectives and experiences play an important role in maximizing creativity and productivity.
					</Card>
				</div>
			</section>

			<section className="bg-gray-100 py-10 lg:py-16">
				<div className="container mx-auto">
					<h2 className="text-gray-800 text-center font-extrabold text-3xl lg:text-4xl">See what they're saying about Pace</h2>
					<Testimonials />

					<div className="px-4 flex justify-center items-center gap-x-4 border-y border-gray-800 py-2 mt-6 max-w-xl mx-auto sm:px-0 sm:mt-10 sm:py-4 md:max-w-3xl lg:mt-12 lg:gap-x-6">
						<img className="h-28 sm:h-36 md:h-40 lg:h-44" src={Top200WorkplacesImg} alt="Star Tribune Top 200 Workplaces Award" />
						<p className="italic font-bold text-lg sm:leading-normal sm:text-2xl md:leading-tight md:text-4xl lg:leading-snug lg:text-4xl">Pace International has been named one of the Top 200 Workplaces in Minnesota by the Star Tribune!</p>
					</div>

					<div className="flex justify-center items-center pt-12">
						<a className="inline-block uppercase font-extrabold text-sm text-white bg-gradient-to-b from-green-400 via-green-500 to-green-500 py-2 px-4 rounded-full sm:py-3 sm:px-6 sm:text-lg md:text-sm xl:text-lg" target="_blank" rel="noopener noreferrer" href="https://www.indeed.com/jobs?q=Pace%20International&l=Rochester%2C%20MN&vjk=b0629b2060e2777c">View Open Positions</a>
					</div>
				</div>
			</section>

			<section className="container mx-auto text-center py-16 px-4">
				<h2 className="uppercase font-extrabold text-4xl">Don't be shy, let's connect!</h2>
				<div className="py-9">
					<a className="w-16 h-16 inline-block" href="https://www.facebook.com/PaceInternationalINC" rel="noreferrer noopener" target="_blank">
						<svg className="text-gray-400 hover:text-green-400 transition duration-300 ease-in-out" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
							<path fillRule="evenodd" clipRule="evenodd" d="M56.18 0A7.823 7.823 0 0164 7.82v48.36A7.823 7.823 0 0156.18 64H42.873V39.886h8.325l1.584-10.328h-9.909v-6.702c0-2.826 1.384-5.58 5.823-5.58H53.2V8.484s-4.089-.698-7.998-.698c-8.16 0-13.495 4.946-13.495 13.9v7.872h-9.071v10.328h9.071V64H7.82A7.823 7.823 0 010 56.18V7.82A7.823 7.823 0 017.82 0h48.36z" fill="currentColor" />
						</svg>
					</a>
					<a className="w-16 h-16 inline-block mx-9" href="https://www.linkedin.com/company/pace-electronics/mycompany/" rel="noreferrer noopener" target="_blank">
						<svg className="text-gray-400 hover:text-green-400 transition duration-300 ease-in-out" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
							<path fillRule="evenodd" clipRule="evenodd" d="M64 8c0-4.415-3.585-8-8-8H8C3.585 0 0 3.585 0 8v48c0 4.415 3.585 8 8 8h48c4.415 0 8-3.585 8-8V8z" fill="currentColor" />
							<path className="text-white" d="M18.345 22.344H7.625v32.245h10.72V22.344zM13.064 6.781C9.396 6.781 7 9.193 7 12.353c0 3.094 2.323 5.572 5.922 5.572h.07c3.737 0 6.064-2.478 6.064-5.572-.07-3.16-2.326-5.572-5.992-5.572zM44.658 21.563c-5.686 0-8.233 3.127-9.66 5.323v-4.565H24.282c.142 3.024 0 32.245 0 32.245h10.717V36.558c0-.964.07-1.928.354-2.617.773-1.925 2.538-3.919 5.499-3.919 3.876 0 5.43 2.959 5.43 7.291v17.253h10.717V36.075c0-9.905-5.288-14.513-12.34-14.513z" fill="currentColor" />
						</svg>
					</a>
					<a className="w-16 h-16 inline-block" href="https://twitter.com/paceintl" rel="noreferrer noopener" target="_blank">
						<svg className="text-gray-400 hover:text-green-400 transition duration-300 ease-in-out" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
							<rect width="64" height="64" rx="8" fill="currentColor" />
							<path className="text-white" d="M23.837 50c16.604 0 25.686-13.851 25.686-25.863 0-.394 0-.785-.027-1.175A18.44 18.44 0 0 0 54 18.256a17.923 17.923 0 0 1-5.185 1.43 9.113 9.113 0 0 0 3.969-5.027 18.018 18.018 0 0 1-5.733 2.206A9.01 9.01 0 0 0 40.47 14c-4.957 0-9.036 4.107-9.036 9.098 0 .693.079 1.383.234 2.058a25.582 25.582 0 0 1-18.605-9.498c-2.382 4.13-1.15 9.482 2.795 12.135a8.913 8.913 0 0 1-4.097-1.138v.115c.001 4.31 3.046 8.057 7.242 8.91a8.954 8.954 0 0 1-4.076.157c1.18 3.693 4.582 6.24 8.434 6.312A18.039 18.039 0 0 1 10 45.917a25.432 25.432 0 0 0 13.837 4.076" fill="currentColor" />
						</svg>
					</a>
				</div>
			</section>
		</>
	)
}

Careers.Layout = Layout

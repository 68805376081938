import { SVGAttributes } from 'react'

export default function Bandage(attributes: SVGAttributes<SVGSVGElement>): JSX.Element {
	return (
		<svg {...attributes} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97 96">
			<path className="text-gray-800" d="M48.5 96c26.51 0 48-21.49 48-48S75.01 0 48.5 0 .5 21.49.5 48s21.49 48 48 48z" fill="currentColor"/>
			<path className="text-gray-300" d="M24.244 72.256c-4.8-4.8-4.8-12.672 0-17.408l31.04-31.04c4.8-4.8 12.672-4.8 17.408 0 4.8 4.8 4.8 12.672 0 17.408l-31.04 31.04c-4.736 4.8-12.608 4.8-17.408 0z" fill="currentColor"/>
			<path className="text-green-400" d="M27.956 44.864l17.408-17.408-3.648-3.648c-4.8-4.8-12.672-4.8-17.408 0-4.8 4.8-4.8 12.672 0 17.408l3.648 3.648zM69.044 51.136L51.636 68.544l3.648 3.648c4.8 4.8 12.672 4.8 17.408 0 4.8-4.8 4.8-12.672 0-17.408l-3.648-3.648z" fill="currentColor"/>
			<path className="text-white" d="M68.852 28.928a1.28 1.28 0 100-2.56 1.28 1.28 0 000 2.56zM64.244 29.824a1.28 1.28 0 100-2.56 1.28 1.28 0 000 2.56zM67.956 33.536a1.28 1.28 0 100-2.56 1.28 1.28 0 000 2.56zM63.412 34.368a1.28 1.28 0 100-2.56 1.28 1.28 0 000 2.56zM59.7 30.72a1.28 1.28 0 100-2.56 1.28 1.28 0 000 2.56zM67.06 38.08a1.28 1.28 0 100-2.56 1.28 1.28 0 000 2.56zM28.148 69.632a1.28 1.28 0 100-2.56 1.28 1.28 0 000 2.56zM32.756 68.736a1.28 1.28 0 100-2.56 1.28 1.28 0 000 2.56zM29.044 65.024a1.28 1.28 0 100-2.56 1.28 1.28 0 000 2.56zM33.588 64.192a1.28 1.28 0 100-2.56 1.28 1.28 0 000 2.56zM37.3 67.84a1.28 1.28 0 100-2.56 1.28 1.28 0 000 2.56zM29.94 60.48a1.28 1.28 0 100-2.56 1.28 1.28 0 000 2.56zM41.972 61.76l-7.232-7.232c-.768-.768-.768-2.048 0-2.88l17.664-17.664c.768-.768 2.048-.768 2.88 0l7.296 7.296c.768.768.768 2.048 0 2.88l-17.728 17.6c-.768.832-2.048.832-2.88 0z" fill="currentColor"/>
		</svg>
	)
}

import { SVGAttributes } from 'react'

export default function Heart(attributes: SVGAttributes<SVGSVGElement>): JSX.Element {
	return (
		<svg {...attributes} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97 96">
			<path className="text-gray-800" d="M48.5 96c26.51 0 48-21.49 48-48S75.01 0 48.5 0 .5 21.49.5 48s21.49 48 48 48z" fill="currentColor"/>
			<path className="text-gray-300" d="M64.564 24.896c-11.072 0-14.976 8.64-14.976 8.64s-3.904-8.64-14.976-8.64c-10.816 0-16.768 9.28-16.768 17.536 0 17.92 31.744 33.728 31.744 33.728s31.744-15.808 31.744-33.728c0-8.256-5.952-17.536-16.768-17.536z" fill="currentColor"/>
			<path className="text-green-400" d="M30.068 76.736a2.732 2.732 0 01-2.752-2.752v-9.472H17.78a2.732 2.732 0 01-2.752-2.752 2.732 2.732 0 012.752-2.752h9.472v-9.536a2.732 2.732 0 012.752-2.752 2.732 2.732 0 012.752 2.752v9.472h9.472a2.732 2.732 0 012.752 2.752 2.732 2.732 0 01-2.752 2.752H32.82v9.472c0 1.6-1.216 2.816-2.752 2.816z" fill="currentColor"/>
			<path className="text-gray-800" d="M30.068 47.36a2.13 2.13 0 012.112 2.112v10.176h10.176a2.13 2.13 0 012.112 2.112 2.13 2.13 0 01-2.112 2.112H32.18v10.176a2.13 2.13 0 01-2.112 2.112 2.13 2.13 0 01-2.112-2.112v-10.24H17.78a2.13 2.13 0 01-2.112-2.112 2.13 2.13 0 012.112-2.112h10.176V49.472a2.13 2.13 0 012.112-2.112zm0-1.344a3.442 3.442 0 00-3.456 3.456v8.832H17.78a3.442 3.442 0 00-3.456 3.456 3.442 3.442 0 003.456 3.456h8.832v8.832a3.442 3.442 0 003.456 3.456 3.442 3.442 0 003.456-3.456v-8.832h8.832a3.442 3.442 0 003.456-3.456 3.442 3.442 0 00-3.456-3.456H33.46v-8.832c0-1.856-1.536-3.456-3.392-3.456z" fill="currentColor"/>
			<path className="text-white" d="M66.484 55.552l-2.24-13.12c-.064-.256-.448-.256-.512 0l-1.344 8.832c-.064.256-.32.32-.448.064l-3.392-5.696c-.064-.064-.128-.128-.192-.128H43.252a.984.984 0 01-.96-.96c0-.512.448-.96.96-.96h16.192c.064 0 .192.064.192.128l1.152 1.984c.128.192.448.128.448-.064l2.304-15.296c.064-.256.448-.256.512 0l2.56 14.912c.064.256.448.256.512 0l1.856-8.768c.064-.256.384-.256.512 0l1.984 6.912c0 .128.128.192.256.192h12.416c.512 0 .96.448.96.96s-.448.96-.96.96H70.324c-.128 0-.192-.064-.256-.192l-.512-1.792-2.496 12.032c-.128.256-.512.256-.576 0z" fill="currentColor"/>
		</svg>
	)
}

import { SVGAttributes } from 'react'

export default function Diversity(attributes: SVGAttributes<SVGSVGElement>): JSX.Element {
	return (
		<svg {...attributes} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97 96">
			<path className="text-gray-800" d="M48.5 96c26.51 0 48-21.49 48-48S75.01 0 48.5 0 .5 21.49.5 48s21.49 48 48 48z" fill="currentColor"/>
			<path className="text-gray-300" d="M61.5 31.1H49.8v10.8h13.3c-.1-3.9-.6-7.5-1.6-10.8zM49.8 28.4h10.9c-2.4-6.5-6.2-11.1-10.5-12.1h-.4v12.1zM47.2 41.9V31.1H35.5c-.9 3.3-1.5 7-1.6 10.8h13.3zM49.8 58v12.1h.4c4.3-1 8.2-5.6 10.5-12.1H49.8zM21.6 41.9h9.6c.1-3.8.6-7.5 1.5-10.8h-8.2c-1.7 3.2-2.7 6.9-2.9 10.8zM63.5 28.4H71c-3.2-4.9-7.9-8.6-13.5-10.6 2.5 2.6 4.5 6.3 6 10.6zM47.2 58H36.3c2.4 6.5 6.2 11.1 10.5 12h.4V58zM47.2 28.4V16.3h-.4c-4.3 1-8.2 5.6-10.5 12.1h10.9zM32.7 55.3c-.9-3.3-1.4-7-1.5-10.8h-9.6c.2 3.9 1.2 7.5 2.9 10.8h8.2zM33.5 28.4c1.5-4.3 3.5-8 6-10.6-5.6 2-10.3 5.7-13.5 10.6h7.5zM47.2 44.5H33.9c.1 3.9.6 7.5 1.6 10.8h11.7V44.5zM64.3 31.1c.9 3.3 1.4 7 1.5 10.8h9.6c-.2-3.9-1.2-7.5-2.9-10.8h-8.2zM49.8 44.5v10.8h11.7c.9-3.3 1.5-7 1.6-10.8H49.8zM75.4 44.5h-9.6c-.1 3.8-.6 7.5-1.5 10.8h8.2c1.7-3.2 2.7-6.9 2.9-10.8z" fill="currentColor"/>
			<path className="text-gray-300" d="M35.5 55.3h11.7V44.5H33.9c.1 3.9.6 7.5 1.6 10.8zM47.2 58H36.3c2.4 6.5 6.2 11.1 10.5 12h.4V58zM49.8 44.5v10.8h11.7c.9-3.3 1.5-7 1.6-10.8H49.8zM47.2 28.4V16.3h-.4c-4.3 1-8.2 5.6-10.5 12.1h10.9zM75.4 44.5h-9.6c-.1 3.8-.6 7.5-1.5 10.8h8.2c1.7-3.2 2.7-6.9 2.9-10.8zM33.5 58H26c3.2 4.9 7.9 8.6 13.5 10.6C37 66 35 62.3 33.5 58zM49.8 28.4h10.9c-2.4-6.5-6.2-11.1-10.5-12.1h-.4v12.1zM49.8 58v12.1h.4c4.3-1 8.2-5.6 10.5-12.1H49.8zM64.3 31.1c.9 3.3 1.4 7 1.5 10.8h9.6c-.2-3.9-1.2-7.5-2.9-10.8h-8.2zM63.5 58c-1.5 4.3-3.5 8-6 10.6 5.6-2 10.3-5.7 13.5-10.6h-7.5zM49.8 41.9h13.3c-.1-3.9-.6-7.5-1.6-10.8H49.8v10.8zM32.7 55.3c-.9-3.3-1.4-7-1.5-10.8h-9.6c.2 3.9 1.2 7.5 2.9 10.8h8.2zM47.2 41.9V31.1H35.5c-.9 3.3-1.5 7-1.6 10.8h13.3zM21.6 41.9h9.6c.1-3.8.6-7.5 1.5-10.8h-8.2c-1.7 3.2-2.7 6.9-2.9 10.8z" fill="currentColor"/>
			<path className="text-white" d="M66.5 73.9c-7.8 0-11.7-.9-11.7-2.6 0-1 .2-5.8 1.9-7.2 1.5-1.2 5.7-2.9 6.4-2.9h.2c.4.1.8.3 1.1.6.1.1.3.2.5.3.3.2.8.3 1 .3 0 0 .5.1.8.1.3 0 .8-.1.8-.1.1 0 .6-.1.9-.3.2-.1.3-.2.5-.3.3-.2.6-.5 1.1-.6h.2c.7 0 4.9 1.7 6.4 2.9 1.7 1.4 1.9 6.2 1.9 7.2-.3 1.7-4.2 2.6-12 2.6z" fill="currentColor"/>
			<path className="text-gray-800" d="M69.9 62.1c.4 0 4.4 1.5 5.8 2.7 1.4 1.2 1.6 6.5 1.6 6.5 0 .9-4.8 1.7-10.8 1.7-6 0-10.8-.8-10.8-1.7 0 0 .1-5.3 1.6-6.5 1.4-1.2 5.4-2.7 5.8-2.7.3 0 .7.5 1.2.8.6.3 1.3.4 1.3.4s.6.1.9.1c.3 0 .9-.1.9-.1s.7-.1 1.3-.4c.5-.3.9-.8 1.2-.8zm0-1.8h-.2c-.7.1-1.2.5-1.5.7-.1.1-.3.2-.4.3-.2.1-.5.2-.6.2-.2 0-.5.1-.7.1-.1 0-.4 0-.7-.1-.2 0-.5-.1-.6-.2-.1-.1-.2-.2-.4-.3-.4-.3-.8-.7-1.5-.7h-.2c-1.1 0-5.5 1.9-7 3.1-1.9 1.5-2.2 6-2.2 7.8 0 1.1 0 3.6 12.6 3.6s12.6-2.5 12.6-3.5c0-1.9-.3-6.3-2.2-7.9-1.5-1.2-5.9-3.1-7-3.1z" fill="currentColor"/>
			<path className="text-white" d="M66.5 62.7c-3.5 0-6.4-3.6-6.4-7.9 0-5.5 1.9-7.9 6.3-7.9s6.4 2.4 6.4 7.9c0 4.3-2.8 7.9-6.3 7.9z" fill="currentColor"/>
			<path className="text-gray-800" d="M66.5 47.8c3.7 0 5.4 2 5.4 7 0 3.9-2.4 7-5.4 7-3 0-5.4-3.1-5.4-7 0-5.1 1.6-7 5.4-7zm0-1.8c-7.3 0-7.3 6.6-7.3 8.8 0 4.8 3.1 8.7 7 8.8h.2c4 0 7.2-4 7.2-8.8.2-2.2.2-8.8-7.1-8.8z" fill="currentColor"/>
			<path className="text-white" d="M30.5 73.9c-7.8 0-11.7-.9-11.7-2.6 0-1 .2-5.8 1.9-7.2 1.5-1.2 5.7-2.9 6.4-2.9h.2c.4.1.8.3 1.1.6.1.1.3.2.5.3.3.2.8.3 1 .3 0 0 .5.1.8.1.3 0 .8-.1.8-.1.1 0 .6-.1.9-.3.2-.1.3-.2.5-.3.3-.2.6-.5 1.1-.6h.2c.7 0 4.9 1.7 6.4 2.9 1.7 1.4 1.9 6.2 1.9 7.2-.3 1.7-4.2 2.6-12 2.6z" fill="currentColor"/>
			<path className="text-gray-800" d="M33.9 62.1c.4 0 4.4 1.5 5.8 2.7 1.4 1.2 1.6 6.5 1.6 6.5 0 .9-4.8 1.7-10.8 1.7-6 0-10.8-.8-10.8-1.7 0 0 .1-5.3 1.6-6.5 1.4-1.2 5.4-2.7 5.8-2.7.3 0 .7.5 1.2.8.6.3 1.3.4 1.3.4s.6.1.9.1c.3 0 .9-.1.9-.1s.7-.1 1.3-.4c.6-.3.9-.8 1.2-.8zm0-1.8h-.2c-.7.1-1.2.5-1.5.7-.1.1-.3.2-.4.3-.2.1-.5.2-.6.2-.2 0-.5.1-.7.1-.1 0-.4 0-.7-.1-.2 0-.5-.1-.6-.2-.1-.1-.2-.2-.4-.3-.4-.3-.8-.7-1.5-.7h-.2c-1.1 0-5.5 1.9-7 3.1-1.9 1.5-2.2 6-2.2 7.8 0 1.1 0 3.6 12.6 3.6s12.6-2.5 12.6-3.5c0-1.9-.3-6.3-2.2-7.9-1.5-1.2-5.9-3.1-7-3.1z" fill="currentColor"/>
			<path className="text-white" d="M30.5 62.7c-3.5 0-6.4-3.6-6.4-7.9 0-5.5 1.9-7.9 6.3-7.9s6.4 2.4 6.4 7.9c.1 4.3-2.8 7.9-6.3 7.9z" fill="currentColor"/>
			<path className="text-gray-800" d="M30.6 47.8c3.7 0 5.4 2 5.4 7 0 3.9-2.4 7-5.4 7-3 0-5.4-3.1-5.4-7-.1-5.1 1.6-7 5.4-7zm-.1-1.8c-7.3 0-7.3 6.6-7.3 8.8 0 4.8 3.1 8.7 7 8.8h.2c4 0 7.2-4 7.2-8.8.2-2.2.2-8.8-7.1-8.8z" fill="currentColor"/>
			<path className="text-green-400" d="M48.5 67.5c-4.5 0-8.2-4.7-8.2-10.3 0-7.1 2.5-10.3 8.2-10.3 5.7 0 8.3 3.2 8.3 10.3 0 5.6-3.6 10.3-8.1 10.3h-.2z" fill="currentColor"/>
			<path className="text-gray-800" d="M48.6 47.8c5 0 7.3 2.6 7.3 9.4 0 5.2-3.3 9.4-7.3 9.4s-7.3-4.2-7.3-9.4c-.1-6.8 2.1-9.4 7.3-9.4zm-.1-1.8c-8.2 0-9.1 6.4-9.1 11.2 0 5.4 3.1 9.9 7.3 11v.2h3.6v-.2c4.1-1.1 7.3-5.6 7.3-11 0-4.8-.9-11.2-9.1-11.2z" fill="currentColor"/>
			<path className="text-white" d="M48.5 82.6c-3.9 0-7.6-.2-10.4-.7-3.6-.6-5-1.3-5-2.5 0-1.3.3-7.6 2.4-9.4 1.9-1.6 7.5-3.8 8.4-3.8h.2c.5.1.9.4 1.3.7.2.2.4.3.7.5.6.3 1.4.5 1.4.5.2 0 .8.1 1.1.1.3 0 .9-.1 1.1-.1 0 0 .8-.1 1.4-.5.2-.1.5-.3.7-.5.4-.3.8-.6 1.3-.7h.2c.9 0 6.5 2.3 8.4 3.8 2.2 1.8 2.4 8.1 2.4 9.4 0 1.2-1.5 2-5 2.5-3 .4-6.7.7-10.6.7z" fill="currentColor"/>
			<path className="text-gray-800" d="M53.1 67c.5 0 5.9 2.1 7.8 3.6 1.9 1.6 2.1 8.7 2.1 8.7 0 1.3-6.5 2.3-14.5 2.3S34 80.6 34 79.3c0 0 .1-7.1 2.1-8.7C38 69 43.4 67 43.9 67c.4.1.9.6 1.6 1.1.8.4 1.7.6 1.7.6s.8.1 1.3.1c.4 0 1.2-.1 1.2-.1s1-.1 1.7-.6c.8-.4 1.3-1 1.7-1.1zm0-1.8h-.2c-.7.1-1.3.5-1.7.9-.2.2-.4.3-.6.4-.3.2-.9.3-1.1.4-.3 0-.8.1-1 .1-.2 0-.7 0-1-.1-.2 0-.7-.2-1.1-.4-.2-.1-.4-.3-.6-.4-.4-.3-1-.8-1.7-.9h-.2c-1.2 0-7 2.4-9 4-2.4 2-2.7 8.2-2.8 10.1 0 1.1 0 4.2 16.4 4.2s16.4-3.1 16.4-4.1c0-1.9-.4-8.1-2.8-10.1-2-1.7-7.8-4.1-9-4.1z" fill="currentColor"/>
		</svg>
	)
}

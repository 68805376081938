import { SVGAttributes } from 'react'

export default function Vacation(attributes: SVGAttributes<SVGSVGElement>): JSX.Element {
	return (
		<svg {...attributes} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97 96">
			<path className="text-gray-800" d="M48.5 96c26.51 0 48-21.49 48-48S75.01 0 48.5 0 .5 21.49.5 48s21.49 48 48 48z" fill="currentColor"/>
			<path className="text-white" d="M40.244 23.744c-.128 0-.192-.192-.064-.256.96-.576 3.264-1.92 5.056-1.856.128 0 .192-.192.064-.256-1.344-.896-5.12-3.072-8.832-1.472-.064 0-.128 0-.192-.064l-2.88-4.032a.195.195 0 00-.256 0c-.512.576-2.24 2.688-2.624 3.712 0 .064-.128.128-.192.064-.96-.32-6.848-1.92-11.008.896-.128.064-.064.256.064.256.896.064 3.2.32 5.952 1.472.128.064.128.192 0 .256-1.536.768-6.144 2.432-8.256 8.832-.064.128.128.192.256.128 1.344-.768 8.448-4.672 13.504-.896.064 7.808 3.2 16.448 5.056 20.992 2.624 6.336 3.392 4.032 5.248 5.44l1.984-2.624c-2.24-1.664-8.896-11.136-9.024-23.872 3.072-1.792 9.28-1.984 13.376 1.984 5.248 4.864-2.24-8.32-7.232-8.704z" fill="currentColor"/>
			<path className="text-green-400" d="M64.884 43.264c5.655 0 10.24-4.585 10.24-10.24s-4.585-10.24-10.24-10.24-10.24 4.585-10.24 10.24 4.585 10.24 10.24 10.24z" fill="currentColor"/>
			<path className="text-gray-300" d="M43.636 62.912c1.856 1.216 5.696 2.816 10.368-.256 2.752-1.856 5.376-2.752 8-2.816 2.112-.064 4.288.512 6.528 1.6a.849.849 0 001.152-1.152c-3.84-7.808-11.84-13.184-21.12-13.184-9.152 0-17.088 5.248-20.928 12.864a.849.849 0 001.152 1.152c3.392-1.536 8.512-2.368 14.848 1.792z" fill="currentColor"/>
			<path className="text-gray-300" d="M14.132 69.888c-.704 1.024.448 2.624 1.536 3.136 1.728.768 2.752.832 4.608.896h.576c3.2 0 6.208-1.024 8.832-2.944 3.2-2.368 6.848-2.176 10.944.512 5.12 3.392 11.072 3.264 16.384-.32 3.264-2.176 6.272-2.88 11.008.256 4.352 2.88 8.768 3.456 13.632 1.216 1.024-.448 1.92-1.856 1.28-2.816-.704-1.088-2.112-1.6-3.328-1.088-3.008 1.28-5.888 1.088-8.512-.704-3.136-2.112-6.08-3.136-9.024-3.072-2.624.064-5.248.96-8 2.816-4.672 3.136-8.512 1.536-10.368.256-8.128-5.376-14.272-2.496-17.216-.32-3.264 2.432-6.72 2.176-9.024 1.216-1.216-.448-2.56-.064-3.328.96z" fill="currentColor"/>
		</svg>
	)
}

import { SVGAttributes } from 'react'

export default function Giving(attributes: SVGAttributes<SVGSVGElement>): JSX.Element {
	return (
		<svg {...attributes} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97 96">
			<path className="text-gray-800" d="M48.5 96c26.51 0 48-21.49 48-48S75.01 0 48.5 0 .5 21.49.5 48s21.49 48 48 48z" fill="currentColor"/>
			<path className="text-gray-800" d="M48.052 59.008c-.576-.32-5.824-2.944-11.008-7.104-7.04-5.696-10.624-11.328-10.624-16.832 0-6.912 5.12-14.08 13.632-14.08 4.416 0 7.488 1.728 9.408 3.52 1.92-1.792 4.992-3.52 9.408-3.52 8.512 0 13.632 7.168 13.632 14.08 0 5.504-3.584 11.2-10.624 16.832-5.184 4.16-10.432 6.848-11.008 7.104l-1.344.704-1.472-.704zm-8-31.872c-5.12 0-7.488 4.608-7.488 7.936 0 6.72 10.688 14.336 16.832 17.728 6.208-3.392 16.832-10.944 16.832-17.728 0-3.264-2.304-7.936-7.424-7.936-4.8 0-6.528 3.52-6.592 3.648l-2.816 6.272-2.816-6.272c-.064-.128-1.728-3.648-6.528-3.648z" fill="currentColor"/>
			<path className="text-green-400" d="M58.804 22.336c7.68 0 12.288 6.464 12.288 12.736 0 12.224-20.032 22.336-20.928 22.72l-.768.384-.768-.384c-.832-.448-20.928-10.56-20.928-22.72 0-6.272 4.608-12.736 12.288-12.736 4.8 0 7.808 2.24 9.408 4.096 1.664-1.856 4.608-4.096 9.408-4.096zm-9.408 11.392l-1.536-3.52c-.064-.192-2.112-4.416-7.808-4.416-6.08 0-8.832 5.44-8.832 9.28 0 8 12.48 16.192 18.24 19.264 5.76-3.008 18.24-11.264 18.24-19.264 0-3.84-2.752-9.28-8.832-9.28-5.76 0-7.808 4.352-7.808 4.416l-1.664 3.52zm9.408-14.144c-4.224 0-7.296 1.408-9.408 3.136-2.112-1.664-5.184-3.136-9.408-3.136-4.352 0-8.256 1.728-11.072 4.928-2.496 2.816-3.904 6.656-3.904 10.496 0 13.632 20.096 24 22.4 25.152l.768.384 1.216.576 1.216-.576.768-.384c2.304-1.152 22.4-11.52 22.4-25.152 0-3.84-1.408-7.68-3.904-10.496-2.816-3.136-6.72-4.928-11.072-4.928zM53.428 31.36c.192-.384 1.6-2.88 5.376-2.88 4.608 0 6.08 4.416 6.08 6.592 0 2.624-2.112 5.888-6.08 9.472-3.456 3.136-7.296 5.504-9.472 6.72-2.112-1.216-5.952-3.584-9.472-6.72-3.968-3.52-6.08-6.848-6.08-9.472 0-2.176 1.472-6.592 6.08-6.592 3.776 0 5.184 2.496 5.376 2.88l1.536 3.456 2.496 5.504 2.496-5.504 1.664-3.456z" fill="currentColor"/>
			<path className="text-gray-800" d="M58.804 24.064c-6.976 0-9.408 5.44-9.408 5.44s-2.432-5.44-9.408-5.44c-6.784 0-10.56 5.824-10.56 11.008 0 11.264 19.968 21.184 19.968 21.184s19.968-9.92 19.968-21.184c0-5.184-3.712-11.008-10.56-11.008z" fill="#C6C8CF" stroke="currentColor" strokeWidth="3.174" strokeMiterlimit="10"/>
			<path className="text-white" d="M72.436 63.04c-.576-.896-2.496-.256-3.136 0-.64.256-5.376 3.136-5.76 3.2-.384.064-3.2.704-3.712.896-.384.128-.576.32-.64.704.128.384.192.768.256 1.152.064 1.088-.256 1.92-.832 2.56-1.024 1.088-2.688 1.152-3.712 1.152h-.448c-1.088 0-3.264-.256-5.312-.512-1.6-.192-3.2-.384-3.776-.384-1.216 0-2.176.128-2.688.448-.384.192-.896.064-1.088-.384-.192-.384-.064-.896.384-1.088.832-.384 1.984-.64 3.456-.64.64 0 2.176.192 3.968.384 1.984.256 4.16.512 5.12.512h.192c.832 0 2.112 0 2.752-.64.32-.32.448-.768.384-1.344-.064-1.6-1.856-2.688-5.312-3.2-1.984-.32-6.336-.32-7.488-.256h-.704c-2.176-.256-8.512-4.672-13.632-4.672-4.096 0-5.952 1.408-6.272 1.792 0 0-2.944.576-3.2.64-.256.064-.704.384-.64.896.064.512 1.92 10.88 1.984 11.328.064.448.32 1.024.96.896.64-.064 3.392-.448 3.84-.448.448 0 16.256 3.52 18.368 3.52 2.112 0 14.4-4.672 15.36-5.248.96-.576 10.048-7.616 10.688-8.192.64-.576 1.344-1.92.64-3.072z" fill="currentColor"/>
		</svg>
	)
}

import { SVGAttributes } from 'react'

export default function Flexibility(attributes: SVGAttributes<SVGSVGElement>): JSX.Element {
	return (
		<svg {...attributes} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97 96">
			<path className="text-gray-800" d="M48.5 96c26.51 0 48-21.49 48-48S75.01 0 48.5 0 .5 21.49.5 48s21.49 48 48 48z" fill="currentColor"/>
			<path className="text-white" d="M40.3 82.5v-2.8c0-11.2-3.6-18.8-11.5-24.2l-4.1 10.2L13.8 40l25.7-10.9L35 40.2c6.8 4 12.1 9.2 15.7 15.3 3.9 6.7 5.9 14.9 5.9 24.3v2.8H40.3v-.1z" fill="currentColor"/>
			<path className="text-gray-800" d="M34.5 34.3l-2.9 7.1c15 7.9 22.3 20.5 22.3 38.3H43.1c0-13.4-4.8-22.2-15.6-28.3l-2.9 7-7.2-17 17.1-7.1zM44.6 24l-12.3 5.2-17 7.2-5.1 2.2 2.2 5.1 7.2 17L24.8 73l5-12.4.3-.6c1.9 1.7 3.3 3.5 4.5 5.5 2.1 3.8 3.1 8.4 3.1 14.2v5.5h21.8v-5.5c0-9.9-2.1-18.5-6.3-25.6-3.4-5.9-8.3-11-14.6-15l1.1-2.7L44.6 24z" fill="currentColor"/>
			<path className="text-gray-300" d="M40.4 82.5v-2.8c0-9.4 2-17.5 5.9-24.3 3.6-6.1 8.8-11.3 15.7-15.3l-4.5-11L83.2 40 72.3 65.7l-4.1-10.2c-7.9 5.4-11.5 13-11.5 24.2v2.8H40.4z" fill="currentColor"/>
			<path className="text-gray-800" d="M62.5 34.3l17 7.2-7.2 17-2.9-7c-10.8 6.1-15.6 14.9-15.6 28.3H43.1c0-17.8 7.3-30.4 22.3-38.3l-2.9-7.2zM52.4 24l5 12.4 1.1 2.7c-6.3 4.1-11.2 9.1-14.6 15-4.2 7.2-6.3 15.8-6.3 25.6v5.5h21.8v-5.5c0-5.8 1-10.5 3.1-14.2 1.1-2 2.6-3.9 4.5-5.5l.3.6 5 12.4 5.2-12.3 7.2-17 2.2-5.1-5.1-2.2-17-7.2L52.4 24z" fill="currentColor"/>
			<path className="text-green-400" d="M53.9 42.4v-15h7.6L48.4 14.3l-13 13.1h7.7v15s3 2.5 5.4 5.2c3.1-3.4 5.4-5.2 5.4-5.2z" fill="currentColor"/>
		</svg>
	)
}
